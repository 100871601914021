<template>
  <div>
    <div id="content" class="col-md-12">
      <div class="products-category">
        <h3 class="title-category" style="margin-top: 20px;">{{ proName }}</h3>
        <div class="category-desc">
          <div class="row">
            <div class="col-sm-12">
              <div id="bannersection" class="banners animated-background">
                <div>
                  <a href="#" v-if="imageBanner != ''">
                    <img
                      :src="imageCategory"
                      alt="img cate"
                      style="width: 1800px; height: auto"
                    />
                    <br />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Filters -->
        <div
          class="product-filter product-filter-top filters-panel"
          style="display: none"
        >
          <div class="row">
            <div class="col-md-5 col-sm-3 col-xs-12 view-mode">
              <div class="list-view">
                <button
                  class="btn btn-default grid"
                  @click.prevent.stop="display('grid')"
                  :class="showmode == 'grid' ? 'active' : ''"
                  data-view="grid"
                  data-toggle="tooltip"
                  data-original-title="Grid"
                >
                  <i class="fa fa-th"></i>
                </button>
                <button
                  class="btn btn-default list"
                  @click.prevent.stop="display('list')"
                  :class="showmode == 'list' ? 'active' : ''"
                  data-view="list"
                  data-toggle="tooltip"
                  data-original-title="List"
                >
                  <i class="fa fa-th-list"></i>
                </button>
              </div>
            </div>
            <div
              class="short-by-show form-inline text-right col-md-7 col-sm-9 col-xs-12"
            >
              <div class="form-group short-by">
                <label class="control-label" for="input-sort">Sort By:</label>
                <select
                  id="input-sort"
                  class="form-control select-arrow"
                  v-model="selectedsortStatus"
                  @change="sortProducts()"
                >
                  <option
                    v-for="(item, index) in filterStatusArray"
                    :key="index"
                    :value="item.value"
                    selected="selected"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label" for="input-limit">Show:</label>
                <select
                  id="input-limit"
                  class="form-control select-arrow"
                  v-model="selectedProductCount"
                  @change="changeProductCount()"
                >
                  <option
                    v-for="(item, index) in filterProductCount"
                    :key="index"
                    :value="item.value"
                    selected="selected"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="box-pagination col-md-3 col-sm-4 col-xs-12 text-right">
                                <ul class="pagination">
                                    <li class="active"><span>1</span></li>
                                    <li><a href="">2</a></li><li><a href="">&gt;</a></li>
                                    <li><a href="">&gt;|</a></li>
                                </ul>
            </div>-->
          </div>
        </div>
        <!-- //end Filters -->

        <!--changed listings-->
        <div
          class="products-list row nopadding-xs so-filter-gird"
          style="margin-top: 20px"
        >
          <!-- <div class="col-md-12" id="preview-area" v-if="blockLoading">
                            <div class="row text-center">
                                <div class="sk-chase">
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                </div>
                            </div>
          </div>-->

          <div class="col-md-12" v-if="!blockLoading && blocks.length === 0">
            <h4>No Items Found</h4>
          </div>

          <span v-for="(gird, index) in gridblocks" :key="index">
            <div class="col-md-12">
              <a @click="pushTocategory(gird.cat_id, gird.catname)">
                <h3 class="title-category">{{ gird.catname }}  &nbsp;&nbsp;&nbsp;<a style="font-size: 12px; float: right;">View More</a> </h3>
              </a>
            </div>
            <div v-for="(item, index2) in gird.blocks" :key="index2">
              <component
                :is="item.blockdatacomponent"
                v-bind:noslide="true"
                v-bind:data="item"
              ></component>
            </div>
          </span>

          <div class="row">
            <div class="col-md-12 text-center">
              <div
                id="infiniteScrollLoader"
                class="text-center"
                v-infinite-scroll="loadMore"
                infinite-scroll-disabled="busy"
                infinite-scroll-distance="30"
              >
                <img src="/assets/image/product/loader.gif" />
                <h4>LOADING...</h4>
              </div>
            </div>
          </div>
          <!-- <div class="product-layout col-lg-15 col-md-4 col-sm-6 col-xs-12">
                            <div class="product-item-container">
                                <div class="left-block left-b">
                                    <div class="box-label">
                                        <span class="label-product label-new">New</span>
                                    </div>
                                    <div class="product-image-container second_img">
                                        <a @click.prevent.stop="pushToProduct()" target="_self" title="Duis aute irure ">
                                            <img src="assets/image/catalog/demo/product/270/e4.jpg" class="img-1 img-responsive" alt="image1">
                                            <img src="assets/image/catalog/demo/product/270/e7.jpg" class="img-2 img-responsive" alt="image2">
                                        </a>
                                    </div>
                                     
                                    <div class="so-quickview">
                                      <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="quickview.html" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a>
                                    </div>                                                     
                                     

                                    
                                </div>
                                <div class="right-block">
                                    <div class="button-group so-quickview cartinfo--left">
                                        <button type="button" class="addToCart" title="Add to cart" onclick="cart.add('60 ');">
                                            <span>Add to cart </span>   
                                        </button>
                                        <button type="button" class="wishlist btn-button" title="Add to Wish List" onclick="wishlist.add('60');"><i class="fa fa-heart-o"></i><span>Add to Wish List</span>
                                        </button>
                                        <button type="button" class="compare btn-button" title="Compare this Product " onclick="compare.add('60');"><i class="fa fa-retweet"></i><span>Compare this Product</span>
                                        </button>
                                        
                                    </div>
                                    <div class="caption hide-cont">
                                        <div class="ratings">
                                            <div class="rating-box">    <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
                                                <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
                                                <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
                                                <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
                                                <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
                                            </div>
                                            <span class="rating-num">( 2 )</span>
                                        </div>
                                        <h4><a @click.prevent.stop="pushToProduct()" title="Pastrami bacon" target="_self">Duis aute irure </a></h4>
                                        
                                    </div>
                                    <p class="price">
                                      <span class="price-new">$48.00</span>              
                                    </p>

                                    <div class="description item-desc">
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est . </p>
                                    </div>
                                    <div class="list-block">
                                        <button class="addToCart btn-button" type="button" title="Add to Cart" onclick="cart.add('101', '1');"><i class="fa fa-shopping-basket"></i>
                                        </button>
                                        <button class="wishlist btn-button" type="button" title="Add to Wish List" onclick="wishlist.add('101');"><i class="fa fa-heart"></i>
                                        </button>
                                        <button class="compare btn-button" type="button" title="Compare this Product" onclick="compare.add('101');"><i class="fa fa-refresh"></i>
                                        </button>
                                                                                         
                                        <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="quickview.html" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i></a>                                                        
                                         
                                    </div>
                                </div>                                        
                            </div>
          </div>-->
        </div>
        <!--// End Changed listings-->
        <!-- Filters -->
        <!-- <div class="product-filter product-filter-bottom filters-panel">
          <div class="row">
            <div class="col-sm-6 text-left"></div>
            <div
              class="col-sm-6 text-right"
              v-if="this.blocks.length >= 1"
            >Showing 1 to {{this.blocks.length}} of {{this.productFullCount.count}}</div>
          </div>
        </div>-->
        <!-- //end Filters -->
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="productModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="productModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header" style="display: flex; justify-content: space-between; align-items: end;">
            <h5 class="modal-title" id="productModalLongTitle">
              Product Quick View
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ProductModalContent
              ref="ProductModalRef"
              v-bind:pid="this.product_id"
              v-bind:count="this.uniqcount"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn"
              data-dismiss="modal"
              style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductTile from "@/components/product/product_tile_catogary.vue";
import ProductTileBrand from "@/components/product/product_tile_brand.vue";
import ProductTileGrid from "@/components/product/product_tile_grid.vue";

import ProductModalContent from "@/components/product/product_modal.vue";
import mixinCommon from "@/mixins/APICommon";
import mixinProduct from "@/mixins/buyer/APIProduct";
import { async } from "q";

export default {
  components: {
    ProductTile,
    ProductTileBrand,
    ProductTileGrid,
    ProductModalContent,
  },
  mixins: [mixinProduct, mixinCommon],
  data() {
    return {
      image: "",
      breadcrumb: [
        {
          text: "Product List",
          href: "/productlist",
          active: false,
        },
      ],
      filterStatusArray: [
        { value: "", text: "Default" },
        { value: "0", text: "Product Name (A - Z)" },
        { value: "1", text: "Product Name (Z - A)" },
        { value: "2", text: "Price (Low - High)" },
        { value: "3", text: "Price (High - Low)" },
        { value: "4", text: "Rating (High - Low)" },
        { value: "5", text: "Rating (Low - High)" },
        // ,{ value:"6", text: "Model (A - Z)" }
        // ,{ value:"7", text: "Model (Z - A)" }
      ],
      selectedsortStatus: "",
      filterProductCount: [
        { value: "15", text: "15" },
        { value: "25", text: "25" },
        { value: "50", text: "50" },
        { value: "75", text: "75" },
        { value: "100", text: "100" },
      ],
      selectedProductCount: "100",
      proName: "",
      cID: "",
      product_id: "",
      blocks: [],
      gridblocks: [],
      showmode: "list",
      blockLoading: true,
      wishlit: false,
      productFullCount: null,
      busy: false,
      pagicount: 1,
      stoppagi: false,
      uniqcount: 0,
      imageCategory: "",
      mainCategory: {},
    };
  },
  computed: {
    catID() {
      let url = window.location.href.split("id=");
      this.cID = url[1];
      return url[1];
    },
    imageBanner() {
      if (this.imageCategory == "" || this.imageCategory == undefined) {
        return "";
      } else {
        return this.imageCategory;
      }
    },
  },
  watch: {
    cID() {
      this.getCategories();
    },
    $route(to, from) {
      this.$forceUpdate();
      this.callFucntionsByOrder();
    },
    "$route.query.id"() {
      this.$forceUpdate();
      this.callFucntionsByOrder();
      this.getProductsByID();
    },
    "$store.state.filterdata": function () {
      this.blocks = [];
      this.pagicount = 1;
      this.getProductsByID();
    },
  },
  mounted: function () {
    this.bannerload();
    this.handleBreadcrumb();
    this.getProductsByID(this.pagicount);
    this.display("grid");
  },
  created() {
    this.initiateList();
    this.callFucntionsByOrder();
    this.getProductCountValue();
  },
  methods: {
    bannerload() {
      var bannerSectionElement = document.getElementById("bannersection");
      var bannerSectionLength = bannerSectionElement.offsetWidth;

      bannerSectionElement.style.minHeight = bannerSectionLength / 2.63 + "px";
    },
    getCategories: async function () {
      try {
        this.imageCategory = "";
        let response = await this.getCategoryDetailById(this.cID);

        this.mainCategory = response;
            if (this.mainCategory.image != undefined) {
              this.imageCategory = this.mainCategory.image;

              if (this.imageCategory == "") {
                this.imageCategory = "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1634295934/cochchi/portal_user/1634295932740.png";
              }
              return;
            } else {
              this.imageCategory = "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1634295934/cochchi/portal_user/1634295932740.png";
            }
      } catch (error) {
        throw error;
      }
    },
    getURLcID() {
      let url = window.location.href.split("id=");
      this.cID = url[1];
      return url[1];
    },
    callFucntionsByOrder: async function () {
      localStorage.setItem("filterdata", null);

      this.$store.dispatch("fetchFilterData");
      this.getURLcID();
      this.blocks = []; //clear for load new category products
    },
    handleBreadcrumb: function () {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    addToWishList: async function (item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 1 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Added to Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        this.selectedProduct.wishlit = true;
        this.setProductInfo();
      } catch (error) {
        throw error;
      }
    },
    removeFromWishList: async function (item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 2 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Removed from Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        this.selectedProduct.wishlit = false;
        this.setProductInfo();
      } catch (error) {
        throw error;
      }
    },
    getProductsByID: async function (currentpage) {
      // this.blocks = [];
      this.gridblocks = [];
      this.blockLoading = true;
      try {
        let params = "category=" + this.cID;
        params += "&page=" + currentpage;
        params += "&perpage=" + 25;
        if (this.$store.state.filterdata != null) {
          if (this.$store.state.filterdata.brands.length > 0) {
            for (
              let index = 0;
              index < this.$store.state.filterdata.brands.length;
              index++
            ) {
              params += "&brands=" + this.$store.state.filterdata.brands[index];
            }
          }
          if (this.$store.state.filterdata.attributes.length > 0) {
            for (
              let index = 0;
              index < this.$store.state.filterdata.attributes.length;
              index++
            ) {
              params +=
                "&attribute_values=" +
                this.$store.state.filterdata.attributes[index];
            }
          }
          if (this.$store.state.filterdata.searchtext != "") {
            params += "&name=" + this.$store.state.filterdata.searchtext;
          }
        }
        let productlist = await this.getProductListByCIDFilters(params);
        if (productlist.length !== 0) {
          this.proName = "";
          if (localStorage.categoryName) {
            this.proName = localStorage.getItem("categoryName");
          }

          // this.proName = productlist[0].what_is_in_box;
          // localStorage.removeItem("categoryName");
        } else if (productlist.length === 0) {
          if (this.$store.state.category_name === null) {
            this.proName = "";
            this.proName = localStorage.getItem("categoryName");
          } else {
            this.proName = "";
            this.proName = this.$store.state.category_name;
          }
        }
        // this.image =
        //   productlist[0].category.image != undefined
        //     ? productlist[0].category.image
        //     : "";
        let blockdata = [];
        let categoryNames = [];
        for (let index = 0; index < productlist.length; index++) {
          let imgs = [];
          let imagearray = productlist[index].show_image;
          for (let index2 = 0; index2 < imagearray.length; index2++) {
            let imgpath = imagearray[index2].image;
            // let is1 = imgpath.split("upload/")[0];
            // let is4 = imgpath.split("upload/")[1];
            // let is2 = "upload/q_auto,f_auto/w_300,h_300,c_fill/";
            // let is3 = is4.split("/")[1];
            // imgpath = is1 + is2 + is4;
            imgs.push(imgpath);
          }
          if (productlist[index].rate.toString().includes(".")) {
            let value1 = productlist[index].rate.toString().split(".");
            productlist[index].rateAvg = value1[0];
            if (value1[1] === "5" || value1[1] > "5") {
              productlist[index].rateAvg =
                parseInt(productlist[index].rateAvg) + 1;
            }
          } else {
            productlist[index].rateAvg = productlist[index].rate;
          }
          let oldprice = "";
          let newprice = "";
          if (
            productlist[index].simple_products[0].special_price != 0 &&
            productlist[index].simple_products[0].special_price != ""
          ) {
            newprice = productlist[index].simple_products[0].special_price;
            oldprice = productlist[index].simple_products[0].price;
          } else {
            newprice = productlist[index].simple_products[0].price;
            oldprice = "";
          }

          let discounpre = "";

          if (oldprice != "") {
            discounpre = ((oldprice - newprice) / oldprice) * 100;
            discounpre = Math.round(discounpre);
          }

          blockdata.push({
            blockdatacomponent: "ProductTile",
            discountprecentage: discounpre,
            imgpath: imgs,
            item_id: productlist[index]._id,
            prediscount: oldprice,
            price: newprice,
            store: productlist[index].store,
            product_name: productlist[index].product_name,
            primary_product: productlist[index].primary_product,
            model: productlist[index].model,
            rating: productlist[index].rateAvg,
            title: productlist[index].product_name,
            wishlit: productlist[index].wishlit,
            url: "/product/" + productlist[index]._id,
            catname: productlist[index].category.category_name,
            catcode: productlist[index].category.category_code,
            cat_id: productlist[index].category._id,
            madein_srilanka: productlist[index].madein_srilanka
          });

          if (
            !categoryNames.includes(productlist[index].category.category_name)
          ) {
            categoryNames.push(productlist[index].category.category_name);
          }
        }

        for (let index = 0; index < categoryNames.length; index++) {
          const catname = categoryNames[index];

          var fildata = blockdata.filter(function (ele) {
            return ele.catname == catname;
          });

          this.gridblocks.push({
            catname: catname,
            cat_id: fildata[0].cat_id,
            blocks: fildata,
          });
        }
        blockdata.forEach((element) => {
          this.blocks.push(element);
        });

        this.blockLoading = false;

        // support for changeProductCount funtion
        let datacount = this.pagicount * this.selectedProductCount;
        if (this.blocks.length === datacount) {
          this.stoppagi = false;
        } else {
          this.stoppagi = true;
          document.getElementById("infiniteScrollLoader").style.display =
            "none";
        }
      } catch (error) {
        this.blockLoading = false;
      }
    },
    getProductCountValue: async function () {
      try {
        this.productFullCount = await this.getProductCount(
          this.$route.query.id
        );
      } catch (error) {
        throw error;
      }
    },
    pushTocategory(id, str) {
      this.$router
        .push({ name: "Product List", query: { id: id } })
         ;
    },
    sortProducts() {
      if (this.selectedsortStatus === "") {
        location.reload();
      }
      if (this.selectedsortStatus === "0") {
        this.blocks.sort(function (a, b) {
          var x = a.product_name.toLowerCase();
          var y = b.product_name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }
      if (this.selectedsortStatus === "1") {
        this.blocks.sort(function (a, b) {
          var x = a.product_name.toLowerCase();
          var y = b.product_name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "2") {
        this.blocks.sort(function (a, b) {
          return a.price - b.price;
        });
      }
      if (this.selectedsortStatus === "3") {
        this.blocks.sort(function (a, b) {
          return a.price - b.price;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "4") {
        this.blocks.sort(function (a, b) {
          return a.rating - b.rating;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "5") {
        this.blocks.sort(function (a, b) {
          return a.rating - b.rating;
        });
      }
      if (this.selectedsortStatus === "6") {
        this.blocks.sort(function (a, b) {
          var x = a.model.toLowerCase();
          var y = b.model.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }
      if (this.selectedsortStatus === "7") {
        this.blocks.sort(function (a, b) {
          var x = a.model.toLowerCase();
          var y = b.model.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        this.blocks.reverse();
      }
      getProductsByID();
    },
    initiateList() {
      let self = this;
      $(".list-view .btn").each(function () {
        $(this).siblings(".btn").removeClass("active");
      });
    },
    display(view) {
      $(".products-list").removeClass("list grid").addClass(view);
      $(".list-view .btn").removeClass("active");
      if (view == "list") {
        //$('.products-list .product-layout').addClass('col-lg-12');
        // $('.products-list .product-layout .left-block').addClass('col-md-4');
        // $('.products-list .product-layout .right-block').addClass('col-md-8');
        $(".products-list .product-layout .item-desc").removeClass("hidden");
        $(".products-list .product-layout .list-block").removeClass("hidden");
        $(".products-list .product-layout .button-group").addClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "list");
      } else {
        //$('.products-list .product-layout').removeClass('col-lg-12');
        //$('.products-list .product-layout .left-block').removeClass('col-md-4');
        //$('.products-list .product-layout .right-block').removeClass('col-md-8');
        $(".products-list .product-layout .item-desc").addClass("hidden");
        $(".products-list .product-layout .list-block").addClass("hidden");
        $(".products-list .product-layout .button-group").removeClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "grid");
      }

      this.showmode = view;
    },
    pushToProduct() {
      this.$router.push("/product");
    },
    parentModalTrigger(pid) {
      this.uniqcount = this.uniqcount + 1;
      this.product_id = pid;

      this.inputNumValue = 1;
      this.isDisable = true;
      this.idObj = {
        ids: [],
      };

      //    this.setProductInfo();
      $("#productModalCenter").modal("show");
    },
    loadMore: async function (currentpage) {
      if (!this.stoppagi) {
        this.busy = true;

        // await this.getProductsByID(this.pagicount);

        this.pagicount++;
        this.busy = false;
      }
    },
    changeProductCount() {
      this.blocks = [];
      this.stoppagi = false;
      this.busy = false;
      this.pagicount = 2;
      document.getElementById("infiniteScrollLoader").style.display = "block";
      this.getProductsByID(1);
    },
  },
};
</script>
<style>
#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.m0p0 {
  margin: 0px !important;
  padding: 0px !important;
}
.select-arrow {
  -webkit-appearance: menulist !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  white-space: pre !important;
  -webkit-rtl-ordering: logical !important;
  color: #555 !important;
  background-color: white !important;
  cursor: default !important;
  border-width: 1px !important;
  border-style: solid !important;
  -o-border-image: initial !important;
  border-image: initial !important;
}
/* ASHEN LOADING ANIMATION PLACE HOLDER */
.placeholder {
  margin: 0 auto;
  max-width: 200px;
  min-height: 100px;
  background-color: #eee;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  /* background-size: 800px 104px; */
  /* min-height: 500px; */
  position: relative;
}
</style>